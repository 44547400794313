import React from 'react'
import Layout from '../Layout.js'
import SplashHeader from '../SplashHeader.js'
import {Link} from 'gatsby'

import paulandtravis from '../projects/paulandtravis.png'
import safedrivers from '../projects/4safedrivers.png'
import stonecast from '../projects/stonecast.png'
import gateway from '../projects/gateway.png'
import willikhomes from '../projects/willikhomes.png'
import caledonialights from '../projects/caledonialights.png'

export default () => {
  return (
    <Layout page="projects">
      <SplashHeader height="80vh"
        image="https://images.unsplash.com/photo-1533378890784-b2a5b0a59d40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1442&q=80">
        {/* TODO josh: make background graphic which shows different project types */}
        <div className="splash-text">
          <h1 className="display-5 mb-4">
            <strong>What We Do</strong>
          </h1>
          <p className="mb-5">
            If you need something built, we can build it.<br/>
            If you need something improved, we can do that too.<br/>
            It's pretty simple really.
          </p>
          <Link className="btn btn-lg btn-callout" to="/hire">Let's talk details</Link>
        </div>
        {/* TODO josh: add down scroll prompt*/}
      </SplashHeader>
      <div className="container-fluid">
        <h2 className="mt-4 mb-3">Our History</h2>
        <div className="row">
          <div className="col">
            <div className="project-gallery card-group">
              <div className="project card">
                <img className="card-img-top" src={paulandtravis}/>
                <div className="card-body">
                  <h5 className="card-title">
                    Paul and Travis <OutLink to="https://www.paulandtravis.ca"/>
                  </h5>
                  <p className="card-text">
                    Real estate sales website with custom listing editor system
                    and youtube integration
                  </p>
                </div>
              </div>
              <div className="project card">
                <img className="card-img-top" src={safedrivers}/>
                <div className="card-body">
                  <h5 className="card-title">
                    4SafeDrivers <OutLink to="https://www.4safedrivers.com"/>
                  </h5>
                  <p className="card-text">
                    Service website with custom checkout and order management
                    system
                  </p>
                </div>
              </div>
              <div className="project card">
                <img className="card-img-top" src={stonecast}/>
                <div className="card-body">
                  <h5 className="card-title">
                    Stonecast Contracting{' '}
                    <OutLink to="https://www.stonecastcontracting.ca/"/>
                  </h5>
                  <p className="card-text">
                    Corporate website to show case products, services, and past
                    projects
                  </p>
                </div>
              </div>
              <div className="project card">
                <img className="card-img-top" src={gateway}/>
                <div className="card-body">
                  <h5 className="card-title">
                    Gateway Caledonia{' '}
                    <OutLink to="https://www.gw.church/"/>
                  </h5>
                  <p className="card-text">
                    Custom embedded content for a church website hosted on the
                    Wix platform.
                  </p>
                </div>
              </div>
              <div className="project card">
                <img className="card-img-top" src={willikhomes}/>
                <div className="card-body">
                  <h5 className="card-title">
                    Willik Homes{' '}
                    <OutLink to="https://www.willikhomes.ca/listings"/>
                  </h5>
                  <p className="card-text">
                    Residential construction website with listing and file
                    management system.
                  </p>
                </div>
              </div>
              <div className="project card">
                <img className="card-img-top" src={caledonialights}/>
                <div className="card-body">
                  <h5 className="card-title">
                    Caledonia Lights{' '}
                    <OutLink to="https://caledonialights.com/"/>
                  </h5>
                  <p className="card-text">
                    An interactive christmas display which allows people to play
                    a game of <em>Simon Says</em> with the Christmas tree
                    display
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function Row({children}) {
  return <div className="mb-3">{children}</div>
}

function Input(props) {
  if (props.type==='textarea') {
    return <textarea className="form-control" {...props}/>
  }
  return (
    <input className="form-control" {...props} />
  )
}

function OutLink({to}) {
  return (
    <a href={to}>
      <small>link</small>
    </a>
  )
}
